export const SITE_ORIGIN = "https://dlevs.com";

export const CLOUDFLARE_IMAGE_URL =
	"https://imagedelivery.net/-WvAFST2VKZep0rhc0An4A";

export const SENTRY_ORG = "dan-levett-development";
export const SENTRY_PROJECT = "dlevs-com";
export const SENTRY_DSN =
	"https://54b0d0212bb1898da1a77da60dc976d7@o4507322054868992.ingest.de.sentry.io/4507322056638544";

/**
 * Map of Cloudflare image variant names.
 */
export const CLOUDFLARE_VARIANT_MAP = {
	/**
	 * 1800x1800
	 *
	 * 🚩 If you change this, update the `resize` value in the package.json
	 * `images:sync` script to match. 🚩
	 */
	large: "lg",
	/** 1000x1000 */
	medium: "md",
	/** 500x500 */
	small: "sm",
};

/**
 * True if the app should serve local images instead of the CDN.
 * These are not processed, so can be very large. They also won't
 * be present on the production server.
 *
 * It's recommended to use the CDN images even in development so
 * that performance (both download size and render jank) can be
 * measured.
 */
export const LOCAL_IMAGES = false; // process.env.LOCAL_IMAGES === "true";

if (LOCAL_IMAGES) {
	console.warn("Using local images. This is not suitable for production.");
}
